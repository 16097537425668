<template>
  <div class="main">
    <back />
    <div class="globle_table">
      <el-table :data="tableData" style="width: 100%" max-height="540">
        <el-table-column prop="number" label="序号" width='80' show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
          </template>
        </el-table-column>
        <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="productName" label="商品名称" show-overflow-tooltip>
          <template slot-scope="scope">
            <span :title="scope.row.productName">{{ scope.row.productName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="brand" label="品牌" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="categoryName" label="分类" show-overflow-tooltip>
          <template slot-scope="scope">
            <span :title="scope.row.categoryName">{{ scope.row.categoryName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="feeRate" label="供货价费率（%）" show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [
        {}
      ],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created () {
    this.getProductList()
  },
  methods: {
    // 品牌
    getProductList () {
      // 取出本地缓存serviceInfo数据
      let { bizId, bizType, feeRate, activeName } = JSON.parse(localStorage.getItem('serviceInfo'))
      let obj = this.$api.supplyFeeRatecategoryDetail;
      let obj1 = this.$api.supplyFeeRatebrandDetail;
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        bizId: bizId,
        bizType: bizType,
        feeRate: feeRate,
      }
      // 2:品牌，3:品类
      if (activeName == '2') {
        this.$axios.get(obj1, {
          params: params,
        }).then((res) => {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        });
      } else {
        this.$axios.get(obj, {
          params: params,
        }).then((res) => {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        });
      }

    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.pageSize = e;
      this.currentPage = 1;
      this.tableData = [];
      this.getProductList();
    },
    // 换页
    handleCurrentChange () {
      this.page += 1;
      this.tableData = [];
      this.getProductList();
    },
  }
}
</script>

<style lang="scss" scoped>
.main {
  padding: 20px 30px;

  .back {
    padding: 0px !important;
  }
}
</style>